import { createApp } from 'vue';
import { createWebHistory, createRouter } from 'vue-router';
import routes from './routes';
import App from './components/App.vue';
import 'intersection-observer';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!window.Laravel.loggedIn) {
      return next({
        name: 'login',
        query: { redirect: to.fullPath },
      });
    }
  }
  if (to.matched.some((record) => record.meta.permission && !window.Laravel.user.role[record.meta.permission])) {
    return next({
      name: 'dashboard',
    });
  }
  if (to.matched.some((record) => record.meta.redirectIfAuthenticated)) {
    if (window.Laravel.loggedIn) {
      return next({
        name: from.name ? from.name : 'dashboard',
        query: { redirect: to.name },
      });
    }
  }
  return next();
});

export default createApp(App)
  .use(router)
  .mount('#app');
